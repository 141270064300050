import { ResponseBody } from 'globalTypes';
import apiConfig from './apiConfig';
import connectorGridApiHeader from '../constants/connectorGridApiHeader.ts';

/*
 * Send data elements to Connector Grid:
 * - Includes uID if available
 * - Handles POST request to Connector Grid
*/
const sendDataToConnectorGrid = async (userProfileData: ResponseBody): Promise<any | null> => {

    try {
        const response = await fetch(apiConfig.connectorGrid.sendData, {
            method: 'POST',
            headers: connectorGridApiHeader,
            body: JSON.stringify(userProfileData)
        });

        if(!response.ok) {
            throw new Error(`Network request to Connector Grid failed! Recieved status code: ${response.status}`);
        }
        
        //return data = await response.json();
        const data = await response.json();
        //console.log(data);
        return data;
    } catch(error) {
        const err = error as Error;
        console.error(`Error in sendDataToConnectorGrid: ${err.message || err}`);
    }
};

export default sendDataToConnectorGrid;