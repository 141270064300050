import { DefaultFormFieldMappings, ResponseBody } from 'globalTypes';
import defaultFormFieldMappings from '../constants/formFieldMappings.ts'
import { getCookieValue } from '../collectors/collectBrowserCookies.ts';
import sendDataToConnectorGrid from './sendData.ts';
import getUniversalIdCookie from './getUniversalIdCookie.ts';

const getAndSendFormData = (formDataObject: object, userProfileData: ResponseBody) => {
    // Map form data to standard name
    const mapFormFieldNames = (standardFormFieldName: string) => {
        for(const standardField in defaultFormFieldMappings) {
            if(defaultFormFieldMappings[standardField as keyof DefaultFormFieldMappings].includes(standardFormFieldName)) {
                return standardField;
            }
        }
        return standardFormFieldName;
    };
    const { piiData, nonPiiData } = userProfileData;
    for(const formField in formDataObject) {
        const standardName = mapFormFieldNames(formField);
        if(defaultFormFieldMappings.hasOwnProperty(standardName)) {
            if (standardName in piiData) {
                piiData[standardName as keyof object] = formDataObject[formField as keyof object];
            }
            if (standardName in nonPiiData) {
                nonPiiData[standardName as keyof object] = formDataObject[formField as keyof object];
            }
        }
    }

    console.log('UNIVERSAL ID: __SEND_DATA: FORM DATA')
    const uID = getCookieValue('uID');
    return !uID ? getUniversalIdCookie(userProfileData) : sendDataToConnectorGrid(userProfileData);
};

export default getAndSendFormData;