import apiConfig from '../api/apiConfig.ts';
// import getMainDomainFromCurrentUrl from '../collectors/collectDomain.ts';
import { ResponseBody } from 'globalTypes';
import connectorGridApiHeader from '../constants/connectorGridApiHeader.ts';

// Set the uID cookie in the browser
// const setUniversalIdCookie = (cookieName: string, cookieValue?: string): string => {
//     const domain = getMainDomainFromCurrentUrl();
//     const cookieString = `${cookieName} = ${(cookieValue || '')}; Domain=${domain}; Max-Age=86400; Path=/; SameSite=None; Secure`;
//     return document.cookie = cookieString;
// };

/* 
 * Retrieve uID cookie and send data to connector grid:
 * If a uID exists, include it in the data.
 * Otherwise, send data without uID.
 */
const getUniversalIdCookie = async (userProfileData: ResponseBody) =>  {
    const userProfileDataWithoutUID = JSON.parse(JSON.stringify(userProfileData));
    delete userProfileDataWithoutUID.universalIds.uID;
    try {
        const response = await fetch(apiConfig.connectorGrid.getUIDCookie, {
            method: 'POST',
            credentials: 'include',
            headers: connectorGridApiHeader,
            body: JSON.stringify(userProfileDataWithoutUID)
        });
        if(!response.ok) {
            throw new Error(`Network request to Connector Grid failed! Recieved status code: ${response.status}`);
        }
        return await response.json();
        // setUniversalIdCookie('uID', data.uID);
    } catch(error) {
        const err = error as Error;
        console.error(`Error in getUniversalIdCookie: ${err.message || err}`);
    }
};

export default getUniversalIdCookie;